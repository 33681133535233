class Multiplex {

  constructor(options) {
    var defaults = {
      adSlot: '/75351959/Onmed.gr/multiplex',
      parentId: 'multiplex-parent',
      elementId: 'multiplex-1'
    };
    this.params = Object.assign({}, defaults, options || {});
    this.start();
  }

  start() {

    if (typeof googletag === 'undefined' || typeof googletag.cmd === 'undefined') {
      return;
    }

    this.parent = document.getElementById(this.params.parentId);
    this.element = document.getElementById(this.params.elementId);

    if (!this.parent || !this.element) {
      return;
    }

    this.parent.setAttribute('style', 'width: ' + this.parent.clientWidth + 'px');
    this.element.setAttribute('style', 'width: ' + this.parent.clientWidth + 'px');
    this.slot = null;

    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver((changes, observer) => {
        if (changes[0].isIntersecting) {
          observer.unobserve(this.parent);
          this.render();
        }
      }, {
        threshold: 0,
        rootMargin: '300px'
      });
      observer.observe(this.parent);
    } else {
      this.render();
    }
  }

  render() {
    googletag.cmd.push(() => {
      this.slot = googletag.defineSlot(this.params.adSlot, 'fluid', this.params.elementId).addService(googletag.pubads());
      googletag.display(this.params.elementId);
    });
  }

}

export {
  Multiplex as
  default
};
