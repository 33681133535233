import Cookies from 'js-cookie';
import Commercial from './commercial';

class Cmp {

	constructor(options) {
		let defaults = {
			prebid: true,
			google: true,
			png: true,
			tempo: true,
			exitbee: true,
			butterfly: true,
			preroll: true,
			agora: true,
			unilever: true,
			groupm: true,
			nielsen: true,
			smartAdserver: true
		};
		this.params = Object.assign({}, defaults, options || {});
		this.start();
	}

	start() {

		if (typeof __nocmp !== 'undefined') {
			this.loadGoogleVendor(false);
			return;
		}

		// If CMP is not loaded check again after 1 second
		if (typeof __tcfapi === 'undefined') {

			window.setTimeout(() => {
				this.start();
			}, 1000);

			return;
		}

		var self = this;

		// Check consent
		__tcfapi('addEventListener', 2, function (tcData, success) {

			if (!success) {
				return;
			}

			if (tcData.eventStatus === 'useractioncomplete' || tcData.eventStatus === 'tcloaded') {

				var consents = tcData.purpose.consents;
				var legitimateInterests = tcData.purpose.legitimateInterests;

				/*
				1: Store and/or access information on a device
				2: Select basic ads
				3: Create a personalised ads profile
				4: Select personalised ads
				5: Create a personalised content profile
				6: Select personalised content
				7: Measure ad performance
				8: Measure content performance
				9: Apply market research to generate audience insights
				10: Develop and improve products
				*/

				var can_1 = consents['1'] ? 1 : 0;
				var can_2 = consents['2'] ? 1 : 0;
				var can_3 = consents['3'] ? 1 : 0;
				var can_4 = consents['4'] ? 1 : 0;
				var can_5 = consents['5'] ? 1 : 0;
				var can_6 = consents['6'] ? 1 : 0;
				var can_7 = consents['7'] ? 1 : 0;
				var can_8 = consents['8'] ? 1 : 0;
				var can_9 = consents['9'] ? 1 : 0;
				var can_10 = consents['10'] ? 1 : 0;

				var may_1 = legitimateInterests['1'] ? 1 : 0;
				var may_2 = legitimateInterests['2'] ? 1 : 0;
				var may_3 = legitimateInterests['3'] ? 1 : 0;
				var may_4 = legitimateInterests['4'] ? 1 : 0;
				var may_5 = legitimateInterests['5'] ? 1 : 0;
				var may_6 = legitimateInterests['6'] ? 1 : 0;
				var may_7 = legitimateInterests['7'] ? 1 : 0;
				var may_8 = legitimateInterests['8'] ? 1 : 0;
				var may_9 = legitimateInterests['9'] ? 1 : 0;
				var may_10 = legitimateInterests['10'] ? 1 : 0;

				// Store and/or access information on a device (Purpose 1)
				if (!can_1) {
					self.loadGoogleVendor(false);
					return;
				}

				// Google Advertising Products
				// Action = create a new commercial.js module
				if (self.params.google == true && tcData.vendor.consents['755'] && tcData.vendor.legitimateInterests['755']) {
					if ((can_2 || may_2) && (can_7 || may_7) && (can_9 || may_9) && (can_10 || may_10)) {
						let personalized = can_3 && can_4;
						self.loadGoogleVendor(personalized);
					}
				}

				// Neustar for P&G Vendor id: 577
				// Action = Declare function to window & load vendor script
				if (self.params.png == true && tcData.vendor.consents['577']) {
					if ((can_2 || may_2) && (can_3 || may_3) && (can_4 || may_4) && (can_5 || may_5) &&
						(can_6 || may_6) && (can_7 || may_7) && (can_8 || may_8) && (can_9 || may_9)) {
						self.loadPnGVendor(tcData);
					}
				}

				// Semasio for Tempo id: 84
				// Action = Load vendor pixel script
				if (self.params.tempo == true && tcData.vendor.consents['84']) {
					if (can_3 && can_9 && can_10) {
						self.loadTempoVendor(tcData);
					}
				}

				// Mookie for GroupM Vendor id: 98
				// Action = Declare function to window & load vendor script
				if (self.params.groupm && tcData.vendor.consents['98']) {
					if ((can_2 || may_2) && (can_3 || may_3) && (can_4 || may_4) && (can_5 || may_5) &&
						(can_6 || may_6) && (can_7 || may_7) && (can_8 || may_8) && (can_9 || may_9) && (can_10 || may_10)) {
						self.loadGroupmVendor(tcData);
					}
				}

				// Taboola for Project agora id: 42
				// Action = Load vendor script
				if (self.params.agora == true && tcData.vendor.consents['42']) {
					if (can_3 && can_9 && can_10) {
						//self.loadTaboolaVendor(tcData);
					}
				}

				// Exitbee id: 1027
				// Action = Load vendor script
				if (self.params.exitbee == true && tcData.vendor.consents['1027']) {
					if ((can_2 || may_2) && (can_3 || may_3) && (can_4 || may_4) && (can_5 || may_5) &&
						(can_6 || may_6) && (can_7 || may_7) && (can_8 || may_8)) {
						self.loadExitbeeVendor(tcData);
					}
				}

				// Nielsen id: 812
				// Action = Load vendor script
				if (self.params.nielsen == true && tcData.vendor.consents['812']) {
					if (can_1 && can_9 && can_10) {
						self.loadNielsenVendor(tcData);
					}
				}

				// Smart Adserver id: 45
				// Action = Load vendor script
				if (self.params.smartAdserver == true && tcData.vendor.consents['45']) {
					if (can_1 && can_2 && can_4 && can_7 && can_10) {
						self.loadSmartAdserver(tcData);
					}
				}

				window.__tcfapi('getNonIABVendorConsents', 2, function (nonIabConsent, nonIabSuccess) {
					if (nonIabSuccess && nonIabConsent.nonIabVendorConsents) {

						// Unilever Vendor id (non IAB): 1
						// Action = load vendor script
						/*
						if (self.params.unilever == true && window.unileverApplies && nonIabConsent.nonIabVendorConsents[4]) {
							if ((can_2 || may_2) && can_3 && can_4 && can_5 && can_6 && (can_7 || may_7) &&
								(can_8 || may_8) && can_9) {
								self.loadUnileverVendor();
							}
						}
						*/

						// Butterfly Vendor id (non IAB): 3
						// Action = load vendor script
						if (self.params.butterfly == true && nonIabConsent.nonIabVendorConsents[5]) {
							if ((can_2 || may_2) && can_3 && can_4 && can_5 && can_6 && (can_7 || may_7) &&
								(can_8 || may_8) && can_9) {
								self.loadButterflyVendor();
							}
						}

					}
				});

			}
		});
	}

	loadGoogleVendor(personalized) {

		new Commercial({
			loadScripts: true,
			personalized: personalized,
			prebid: this.params.prebid,
			preroll: this.params.preroll
		});
	}

	loadTaboolaVendor(tcData) {

		if (typeof window.dfp_targeting.pagetype !== 'undefined') {
			var pageType = window.dfp_targeting.pagetype;
			var loadTaboola = pageType == 'story' || pageType == 'video' || pageType == 'gallery' || null;

			if (loadTaboola) {
				var script = document.createElement('script');
				script.src = 'https://agorahtag.tech/c/onmed.js';
				script.async = true;
				document.head.append(script);
			}
		}
	}

	loadPnGVendor(tcData) {

		var GeoGdprApplies = tcData.gdprApplies ? 1 : 0;

		// P&G
		const png = document.createElement('script');
		png.src = "https://pghub.io/js/pandg-sdk.js";
		png.async = true;
		document.head.appendChild(png);

		png.onload = function () {

			//if gdpr is not relevant, null values are passed for gdpr related parameters
			//if gdpr is relevant, send gdpr and gdpr consent values
			//if ccpa is not relevant, null values are passed for ccpa related parameters
			//if ccpa is relevant, send ccpa value
			//see integration guide for gdpr and ccpa details
			//bpid = business partner id, this is a unique code for identifying partner data to P&G
			var metadata = {
				gdpr: GeoGdprApplies,
				gdpr_consent: tcData.tcString,
				ccpa: null,
				bp_id: "dpgdigital",

			};

			//required configuration parameters do not change
			var config = {
				name: "P&G",
				pixelUrl: "https://pandg.tapad.com/tag"
			};

			//initialize tag object
			var tagger = Tapad.init(metadata, config);

			//prepare object for passing custom data parameters
			//object can be empty if passing no custom data parameters
			//one or more additional parameters can be added - depending on availability
			var data = {

				//sample custom parameters
				//category: "Parenting",
				//age_range: "26_30",
				//gender: "female"
			};
			if (typeof window.dfp_targeting.section !== 'undefined') {
				data.category = window.dfp_targeting.section;
			}
			if (typeof window.dfp_targeting.category !== 'undefined') {
				data.subcategory = window.dfp_targeting.category;
			}

			//for more information on firing the tag on custom events see the integration guide
			//fire tag sync when ready
			tagger.sync(data).then(console.log);

		}
	}


	loadTempoVendor(tcData) {
		try {
			var GeoGdprApplies = tcData.gdprApplies ? 1 : 0;
			var smoGDPRFlag = GeoGdprApplies;
			var smoGDPRConsentString = tcData.tcString;
			var smoRand = Math.random() * 100000000000000000;
			var smoTrSeq = (typeof (smoTrSeq) != "undefined") ? smoTrSeq + 1 : 0;
			var smoPrtKey = 'omdgr/1';
			var smoPUrl = escape(window != parent ? document.referrer : document.location.href);
			var smoIframe = window != parent ? '&nIframe=1' : '';
			var smoParams = '&sType=view&nCampaignId=1';
			var smoProto = (document.location.protocol == 'https:') ? "https:" : "http:";
			var smoTrUrl = smoProto + '//uip.semasio.net/' + smoPrtKey + '/info?gdpr=' + smoGDPRFlag + '&gdpr_consent=' + smoGDPRConsentString + '&_referrer=' + smoPUrl + smoParams + '&nJs=1&_ignore=' + smoRand + smoIframe;
			var smoTrImg = new Image(); smoTrImg.src = smoTrUrl;
		} catch (err) { }
	}

	loadExitbeeVendor() {
		(function (e, x, i, t, b) {
			e["ExitBeeObject"] = b; e[b] = e[b] ||
				function () { (e[b].args = e[b].args || []).push(arguments); };
			var a = x.createElement(i);
			var m = x.getElementsByTagName(i)[0];
			a.async = 1; a.src = t; m.parentNode.insertBefore(a, m)
		})
			(window, document, "script", "https://cdn.exitbee.com/xtb.min.js", "xtb")
		xtb("loadSite", "6967");
	}

	loadButterflyVendor() {
		var uiCode = 'WJ-13111';
		var bf = document.createElement('script');
		bf.async = true;
		bf.src = '//call.thebutterfly.eu/Snippet/' + uiCode + '?_=' + Math.floor(Math.random() * 99999999999);
		document.head.appendChild(bf);
	}

	loadGroupmVendor() {
		!function (n, t, a, i) { n.mpfContainr || (n.mpfContainr = function () { a.push(arguments) }, mpfContainr.q = a, (i = t.createElement('script')).type = 'application/javascript', i.async = !0, i.src = '//cdn.mookie1.com/containr.js', t.head.appendChild(i)) }(window, document, []);

		mpfContainr('V2_751522', { 'host': 'gr-gmtdmp.mookie1.com', 'tagType': 'learn', 'src.rand': '[timestamp]' });
	}

	loadNielsenVendor() {
		const nielsen = document.createElement('script');
		nielsen.async = true;
		nielsen.setAttribute("data-ws", "25031");
		nielsen.setAttribute("data-h", "phaistos-ssp.adman.gr");
		nielsen.setAttribute("data-s", "0x0");
		nielsen.src = "https://static.adman.gr/adman.js";
		document.head.appendChild(nielsen);
	}

	loadSmartAdserver() {
		const script = document.createElement('script');
		script.src = "https://t.atmng.io/adweb/EkOCYOc74.prod.js";
		document.head.appendChild(script);
	}

	repropmpt() {
		Cookies.remove('euconsent-v2', {
			path: '/',
			domain: '.' + location.host
		});

		__tcfapi('displayConsentUi', 2, function () { });
	}
}

export {
	Cmp as
		default
};
